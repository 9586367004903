import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { createBrowserHistory } from 'history';
import moment from 'moment';
import React, { lazy, Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { getSentryReduxEnhancer } from '../../../../packs/sentry';
import { ErrorToast } from '../../../../src/components/ErrorToast/ErrorToast';
import { getThemeName } from '../../../../src/functions/getThemeName';
import { errorHandler } from '../../../../src/middleware/errorHandler';
import { rootReducer } from '../../store/rootReducer';
import { Login } from '../Login/Login';
import './Unauthenticated.scss';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { LoadingScreen } from '../../../../src/components/LoadingScreen/LoadingScreen';
import UnauthenticatedStartContent from '../../../../src/components/UnauthenticatedStartContent/UnauthenticatedStartContent';
import { signupRoute } from '../../../../src/components/Routes/Routes';
import { initChatWidget } from '../../../../src/functions/chatWidgetHelper';

const Register = lazy(() => import('../Register/Register'));
const SignUp = lazy(() => import('../SignUp/SignUp'));

const history = createBrowserHistory();

const sentryEnhancer = getSentryReduxEnhancer();

const store = configureStore({
  reducer: rootReducer(history),
  middleware: [
    ...getDefaultMiddleware(),
    routerMiddleware(history),
    errorHandler,
  ],
  enhancers: sentryEnhancer ? [sentryEnhancer] : undefined,
});

// @ts-ignore
moment.locale(window.navigator.userLanguage || window.navigator.language);

export const Unauthenticated: React.FC = () => {
  useEffect(() => {
    initChatWidget();
  }, []);
  return (
    <Suspense fallback={<LoadingScreen />}>
      <div className={classNames('unauthenticated', `app-${getThemeName()}`)}>
        <div className="unauthenticated--body">
          <Provider store={store}>
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            >
              <ErrorToast />
              <ConnectedRouter history={history}>
                <Switch>
                  <Route exact path="/r/register" component={Register} />
                  <Route exact path="/r/resetPassword" component={Register} />
                  <Route
                    exact
                    path={signupRoute}
                    component={UnauthenticatedStartContent}
                  />
                  <Route component={Login} />
                </Switch>
              </ConnectedRouter>
            </GoogleReCaptchaProvider>
          </Provider>
        </div>
      </div>
    </Suspense>
  );
};
