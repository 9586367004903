export const getThemeName = () => {
  const host = window.location.hostname.toLowerCase();
  const subdomainOnly = ['test', 'development'].some(
    value => process.env.NODE_ENV === value
  );
  return host === 'newstream.adoptech.co.uk' ||
    (subdomainOnly && host.startsWith('newstream'))
    ? 'newStream'
    : 'adoptech';
};
