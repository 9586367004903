import { getMetaContent } from './getMetaContent';

export const formSubmit = (
  method: 'get' | 'post',
  path: string,
  params: Record<string, string>
) => {
  const hiddenForm = document.createElement('form');
  hiddenForm.method = method;
  hiddenForm.action = path;

  params['authenticity_token'] = getMetaContent('csrf-token');
  for (const key in params) {
    const hiddenInput = document.createElement('input');
    hiddenInput.type = 'hidden';
    hiddenInput.name = key;
    hiddenInput.value = params[key];
    hiddenForm.appendChild(hiddenInput);
  }

  document.body.appendChild(hiddenForm);
  hiddenForm.submit();
};
