import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import AuthenticationReducer from './authentication/authenticationSlice';
import GlobalReducer from '../../../src/store/global/globalSlice';
import SignupReducer from './signup/signupSlice';

export const rootReducer = (history: History) =>
  combineReducers({
    authentication: AuthenticationReducer,
    global: GlobalReducer,
    router: connectRouter(history),
    signup: SignupReducer,
  });
