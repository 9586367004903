import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../src/components/AdoptechButton/AdoptechButton';
import { LoadingSpinner } from '../../../../src/components/LoadingSpinner/LoadingSpinner';
import { Panel } from '../../../../src/components/Panel/Panel';
import {
  sendSms,
  sendToken,
} from '../../store/authentication/authenticationThunks';
import { ApplicationState } from '../../types/applicationState';
import { InputField } from '../InputField/InputField';
import './TwoFactorAuth.scss';

interface TwoFactorAuthProps {
  email: string;
}

export const TwoFactorAuth: React.FC<TwoFactorAuthProps> = props => {
  const [token, setToken] = useState('');

  const dispatch = useDispatch();
  const requestSms = () => dispatch(sendSms(props.email));

  const authenticationState = useSelector(
    (state: ApplicationState) => state.authentication
  );

  const {
    smsRequesting,
    tokenVerifying,
    isAuthenticated,
    authySmsRequestSuccess: smsMessage,
    authySmsRequestWarning: smsMessageWarning,
    authyTokenError: errorMessage,
  } = authenticationState;

  const baseCss = 'twoFactorAuth';

  const errorMessageClasses = classNames({
    [`${baseCss}--infoMessage`]: true,
    [`${baseCss}--infoMessage--error`]: !!errorMessage,
    [`${baseCss}--infoMessage--hidden`]: !errorMessage,
  });

  const smsMessageClasses = classNames({
    [`${baseCss}--smsMessage`]: true,
    [`${baseCss}--smsMessage--error`]: !!smsMessageWarning,
    [`${baseCss}--smsMessage--hidden`]: !smsMessage && !smsMessageWarning,
  });

  if (isAuthenticated) return <LoadingSpinner />; // If all form requests finished, and redirecting to dashboard
  return (
    <div className={baseCss}>
      <div className={baseCss + '--panel'}>
        <Panel placedOnDarkBackground>
          <div className={baseCss + '--content'}>
            <div className={baseCss + '--header'}>
              Two Factor Authentication
            </div>

            <div className={baseCss + '--subheader'}>
              Please enter the token sent via the Authy App. If you have not
              installed the app, please request for a SMS.
            </div>

            <div className={baseCss + '--tokenLabel'}>Token</div>

            <div className={baseCss + '--tokenField'}>
              <InputField
                autoFocus
                disableAutoComplete
                onChange={value => setToken(value)}
                placeholder="Enter Token"
                type="text"
              />
            </div>
            <div className={errorMessageClasses}>{errorMessage}</div>
            <div className={smsMessageClasses}>
              {smsMessageWarning ? smsMessageWarning : smsMessage}
            </div>
            <div className={baseCss + '--actions'}>
              {smsRequesting ? (
                'Requesting SMS...'
              ) : (
                <div onClick={requestSms} className={baseCss + '--smsRequest'}>
                  Request for SMS
                </div>
              )}
              <AdoptechButton
                disabled={!token || token.trim().length <= 0}
                onClick={() => {
                  const obj = { email: props.email, token };
                  dispatch(sendToken(obj));
                }}
                busy={tokenVerifying}
                variant={AdoptechButtonVariant.Primary}
              >
                Verify
              </AdoptechButton>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};
