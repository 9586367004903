import React, { ReactElement } from 'react';
import StartHeader from '../StartHeader/StartHeader';
import StartFooter from '../StartFooter/StartFooter';
import './StartLayout.scss';
import { useSelector } from 'react-redux';
import { selectUserId } from '../../../selectors/selectUserId';

interface StartLayoutProps {
  rightHandPanelImage?: string;
  rightHandPanel?: ReactElement;
  showLogin?: boolean;
  showProgress?: boolean;
}

export const StartLayout: React.FC<StartLayoutProps> = props => {
  const userId = useSelector(selectUserId);

  return (
    <div className="startLayout">
      <StartHeader
        showLogin={props.showLogin && !userId}
        showProgress={props.showProgress}
      />
      <div className="startLayout--content">
        <div className="startLayout--left">
          <div className="startLayout--leftContainer"> {props.children}</div>
        </div>
        <div className="startLayout--right">
          <div className="startLayout--rightContainer">
            {props.rightHandPanelImage && (
              <img src={props.rightHandPanelImage} />
            )}

            {props.rightHandPanel && props.rightHandPanel}
          </div>
        </div>
      </div>
      <StartFooter />
    </div>
  );
};

StartLayout.defaultProps = {
  showLogin: true,
  showProgress: true,
};
