import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../unauthenticated/src/types/applicationState';
import { SignupPage } from '../../../unauthenticated/src/types/signupPage';
import { StartLayout } from '../Start/StartLayout/StartLayout';
import SignUp from '../../../unauthenticated/src/components/SignUp/SignUp';
import VerificationSentSvg from 'images/verification-sent.svg';
import { VerificationSent } from '../VerificationSent/VerificationSent';
import { RightHandPanel } from '../../../unauthenticated/src/components/SignUp/RightHandPanel';

const UnauthenticatedStartContent: React.FC = () => {
  const currentPage = useSelector(
    (state: ApplicationState) => state.signup.currentPage
  );
  const isGetStarted = currentPage === SignupPage.GetStarted;

  if (isGetStarted)
    return (
      <StartLayout rightHandPanel={<RightHandPanel />}>
        <SignUp />
      </StartLayout>
    );

  return (
    <StartLayout rightHandPanelImage={VerificationSentSvg}>
      <VerificationSent />
    </StartLayout>
  );
};

export default UnauthenticatedStartContent;
