import { ApplicationState } from '../types/applicationState';
import { UserDetails } from '../swagger';
import { createSelector } from '@reduxjs/toolkit';

const getUserDetails = (state: ApplicationState) => state.user?.userDetails;

export const selectUserId = createSelector(
  getUserDetails,
  (userDetails: UserDetails) => {
    return userDetails?.id;
  }
);
