import React from 'react';
import './DividerWithText.scss';

interface DividerWithTextProps {
  text: string;
}

const DividerWithText: React.FC<DividerWithTextProps> = ({ text }) => {
  return (
    <div className="dividerWithText">
      <hr />
      <span className="dividerWithText--text">{text}</span>
      <hr />
    </div>
  );
};

export default DividerWithText;
