import { SignupPage } from '../../types/signupPage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const signupSlice = createSlice({
  initialState: {
    currentPage: SignupPage.GetStarted,
  },
  name: 'signupSlice',
  reducers: {
    forward: state => {
      state.currentPage++;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
  },
});

export const { forward, setCurrentPage } = signupSlice.actions;

export default signupSlice.reducer;
