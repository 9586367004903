import React from 'react';
import ReactDOM from 'react-dom';
import { Unauthenticated } from '../unauthenticated/src/components/Unauthenticated/Unauthenticated';
import { sentryInit } from './sentry';

sentryInit();

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(<Unauthenticated />, document.getElementById('root'));
});
