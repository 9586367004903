import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './RightHandPanel.scss';

const DescriptionRow: React.FC<{ text: string }> = props => {
  const baseCss = 'rightHandPanel';
  return (
    <div className={baseCss + '--descriptionRow'}>
      <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
      {props.text}
    </div>
  );
};

const titles = ['With Adoptech', 'Expert support'];
const topRows = [
  'Cyber Essentials Certification',
  'ISO 27001',
  'ISO 9001',
  'SOC 2',
  '70+ Company Policies, Agreements and Checklists',
  'Suite of platform tools and automations',
  '£25K Cyber Insurance',
];

const bottomRows = [
  'UK based team on-hand to help',
  'Dedicated Project Manager',
];

export const RightHandPanel: React.FC = () => {
  const baseCss = 'rightHandPanel';

  return (
    <div className={baseCss}>
      <div className={baseCss + '--title'}>{titles[0]}</div>
      <div className={baseCss + '--description'}>
        {topRows.map(row => (
          <DescriptionRow text={row} key={row} />
        ))}
      </div>
      <div className={baseCss + '--title'}>{titles[1]}</div>
      <div className={baseCss + '--description'}>
        {bottomRows.map(row => (
          <DescriptionRow text={row} key={row} />
        ))}
      </div>
    </div>
  );
};
